<!-- 
 This component is taking all the screen space.
 It is displayed when the user is not subscribed to the service.
 It is a panel with the detail of the subscription plan advantages.
 A Close button as cross is available to close the panel.
 The design is responsive to adapt to the screen size.
 -->

<div *ngIf="isVisible" class="subscription-paywall">
    <div class="subscription-paywall__header">
        <div class="subscription-paywall__header__title">Subscribe to our service</div>
        <div class="subscription-paywall__header__close" (click)="closePanel()">
            <i class="fa fa-times"></i>
        </div>
    </div>
    <div class="subscription-paywall__content">
        <div class="subscription-paywall__content__plan">
            <div class="subscription-paywall__content__plan__title">Premium</div>
            <div class="subscription-paywall__content__plan__price">4.49$/month <s>14.99$/month</s></div>
            <div class="subscription-paywall__content__plan__advantages">
                <ul style="list-style-type: none; padding-left: 20px;">
                    <li *ngFor="let feature of this.productDescription[1].featureList" style="font-size: 16px; line-height: 1.6; margin-bottom: 10px;">
                        ✅ {{feature}}
                    </li>
                </ul>
                <div class="subscription-paywall__content__plan__advantages__item">
                    <i class="fa fa-check"></i> Access to a selection of content
                </div>
                <div class="subscription-paywall__content__plan__advantages__item">
                    <i class="fa fa-check"></i> Ads
                </div>
            </div>
        </div>

        <div class="subscription-paywall__content__plan">
            <div class="subscription-paywall__content__plan__title"> <!-- class="nt-subtitle" --> Free</div>
            <div class="subscription-paywall__content__plan__price">Free subscription</div>
            <div class="subscription-paywall__content__plan__advantages">
                <ul style="list-style-type: none; padding-left: 20px;">
                    <li *ngFor="let feature of this.productDescription[0].featureList" style="font-size: 16px; line-height: 1.6; margin-bottom: 10px;">
                        ✅ {{feature}}
                    </li>
                </ul>

                <div class="subscription-paywall__content__plan__advantages__item">
                    <i class="fa fa-check"></i> Unlimited access to all the content
                </div>
                <div class="subscription-paywall__content__plan__advantages__item">
                    <i class="fa fa-check"></i> No ads
                </div>
                <div class="subscription-paywall__content__plan__advantages__item">
                    <i class="fa fa-check"></i> Exclusive content
                </div>
            </div>
        </div>
    </div>

    <div class="subscription-paywall__footer">
        <div class="text-center">
            <a mat-raised-button color="accent" [routerLink]="['/subscribe']">
                <div class="cta-big-text">Subscribe</div>
            </a>
        </div>
    </div>
</div>
