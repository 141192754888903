import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core';
import { BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { map, shareReplay, Observable } from 'rxjs';
// import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoginService } from './core/login.service';
import { NeoTradrUser, UserService } from './core/user.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { getPorfolioIdNameList } from './pages/portfolio-page/portfolio_data';
import { StripeService } from './core/stripe.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  ntVersion: string = environment.ntVersion
  ntUser: NeoTradrUser|null = null
  hasValidSubscription: boolean|null = null
  isCollapsed = true;
  isSmallScreen = false;
  isBrowser = false
  isLoggedIn: boolean = false
  countdownString: string = ""
  countdownVisible: boolean = false
  countdownTimeout: any = null

  links: Array<{name: string, page: string, icon?: string}> = [
    {name: 'Best ranked companies', page: 'discover', icon: 'stars'},
    ...getPorfolioIdNameList().map(x => { 
      return {name: x.portfolio_name, page: `portfolio/${x.id}`, icon: 'bar_chart'} 
    }),
    {name: 'Business news', page: 'news', icon: 'article'}
  ]

  constructor(
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private stripeService: StripeService,
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>)  
    {
      this.isBrowser  = isPlatformBrowser(this.platformId);
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => {
          this.isSmallScreen = result.matches;
      });
    }

  ngOnInit(): void {
    if(this.isBrowser){
      this.loginService.isLoggedIn.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn
        console.log('app.components isLoggedIn?: ', isLoggedIn)
        // this.configureCountdown()
        if(isLoggedIn){
          this.loginService.getIdToken().then(idToken => {
            this.userService.getUser(idToken).subscribe(ntUser => {
              console.log('app.components ntUser: ', ntUser)
              this.ntUser = ntUser
            })
            this.stripeService.hasValidSubscription(idToken).subscribe(hasValidSubscription => {
                this.hasValidSubscription = hasValidSubscription
            })
          }).catch(err => {
            console.log('Error:', err)
          })
        } else {
          this.ntUser = null
        }
      })
    }
  }

  goTo(path: string): void {
    this.router.navigate([path])
  }

  logout(): void {
    this.loginService.logout()
  }

  toggleCollapse(): void {
      this.isCollapsed = !this.isCollapsed;
  }

  // async configureCountdown() {
  //   const sleep = async (milliseconds: number) => {
  //     await new Promise(resolve => {
  //       return setTimeout(resolve, milliseconds)
  //     });
  //   };
    
  //   await sleep(30000);
  //   let countDownDate = new Date().getTime();
  //   countDownDate += 10 * 22 * 58 * 21 * 1000
  //   const f_value = localStorage.getItem('f')
  //   if(f_value){
  //     try {
  //       countDownDate = parseFloat(f_value)
  //     } catch (error) {
  //       localStorage.setItem('f', countDownDate.toString())
  //     }
  //   } else {
  //     localStorage.setItem('f', countDownDate.toString())
  //   }

  //   if(this.ntUser == null){
  //     if(this.countdownTimeout == null){ // At init
  //       this.countdownTimeout = setInterval(() => {
  //         const now = new Date().getTime();
  //         const distance = countDownDate - now;
  //         this.countdownVisible = true
    
  //         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //         const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //         const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  //         this.countdownString = `Get Exclusive Free Access by signing up before ${days}d ${hours}h ${minutes}m ${seconds}`;
  //         if (distance < 0) {
  //           clearInterval(this.countdownTimeout);
  //           this.countdownVisible = false
  //           this.countdownTimeout = null
  //           let countDownDate = new Date().getTime();
  //           countDownDate += 10 * 22 * 58 * 21 * 1000
  //           localStorage.setItem('f', countDownDate.toString())
  //         }
  //       }, 1000);
  //     }
  //   } else {
  //     if(this.countdownTimeout != null){
  //       clearInterval(this.countdownTimeout);
  //       this.countdownTimeout = null
  //     }
  //     this.countdownVisible = false
  //   }
  // }
}
