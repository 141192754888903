import { Component } from '@angular/core';
import { NeoTradrUser, UserService } from '../../core/user.service';
import { LoginService } from '../../core/login.service';
import { Router } from '@angular/router';
import { SubscriptionData } from '../../components/subscription-button/subscription-offer/subscription-offer.component';
import { StripeService } from '../../core/stripe.service';


export const productDescription: Array<SubscriptionData> = [
  {
    title: "Start your journey as a Value Investor",
    descriptionText: "Enjoy the powerful features of NeoTradr.",
    featureList: [
      "Access all best ranked companies",
      "Enjoy weekly and/or daily insights in your mailbox",
      "Access features exclusive to logged in users",
      "Keep your favorite companies with you",
    ],
    priceTag: "Free subscription",
    insteadOfPriceTag: ""
  },
  {
    title: "Become a next-gen Value Investor",
    descriptionText: "Unlimited access to all Premium features without being time limited. Enjoy powerful and detailed analysis features.",
    featureList: [
      "Access all best ranked companies",
      "No ads for a cleaner research experience",
      "Enjoy weekly and/or daily insights in your mailbox",
      "Keep your favorite companies with you",
      "Access to all exclusive premium features",
      "Access to technical pillars to know if its the right time to buy",
      "Access to the detail of all pillars",
      "Access to the complete SWOT analysis (Opportunities and Threats)",
      "Unlimited access over time"],
    priceTag: "4.49$/mo",
    insteadOfPriceTag: "14.99$/mo"
  },
  {
    title: "Become a one-time next-gen Value Investor",
    descriptionText: "Enjoy the Premium benefits of NeoTradr without being engaged. This is the perfect solution to try the service.",
    featureList: [
      "Access all best ranked companies",
      "No ads for a cleaner research experience",
      "Enjoy weekly and/or daily insights in your mailbox",
      "Access to all exclusive premium features",
      "Access to technical pillars to know if its the right time to buy",
      "Access to the detail of all pillars",
      "Access to the complete SWOT analysis (Opportunities and Threats)",
      "Try the best of NeoTradr without being engaged"],
    priceTag: "14.99$ for 1 month",
    insteadOfPriceTag: ""
  },
]


@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss']
})
export class SubscriptionPageComponent {
  ntUser: NeoTradrUser | null = null;
  buyDisable: boolean = true;
  hasValidSubscription: boolean|null = null
  panels = productDescription
  constructor(
    private userService: UserService,
    private loginService: LoginService,
    private router: Router,
    private stripe: StripeService){}

    ngOnInit(): void {
      this.getUser()
      this.loginService.isLoggedIn.subscribe(isLoggedIn => {
        if(isLoggedIn){
          this.getUser()
        } else {
          this.ntUser = null
          // Not implemented
        }
      })
    }

    getUser(): void {
      this.loginService.getIdToken().then(idToken => {
        console.log("idToken:", idToken)
        this.userService.getUser(idToken).subscribe(user => {
          console.log("user: ", user)
          this.ntUser = user
        })

        this.stripe.hasValidSubscription(idToken).subscribe(hasValidSubscription => {
          this.hasValidSubscription = hasValidSubscription
        })
      })
    }

    login(): void {
      this.loginService.login()
    }

    subscribeClicked(): void {
      console.log('Subscribing')
      this.stripe.subscribeToPremium()
    }

    buyClicked(): void {
      console.log('Buying')
    }
}
