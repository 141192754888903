import { Component, OnInit, ViewChild, ElementRef, InjectionToken, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PillarsData, PillarsService } from '../../core/pillars.service';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationService } from '../../navigation.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PortfolioData, getPortfolioData, portfolioData } from './portfolio_data';
import { registerables, Chart, ChartDataset, AnimationEvent } from 'chart.js';
import { isPlatformBrowser } from '@angular/common';
import { convert_int_to_ext_symbol, getColorFromScore } from '../../core/helpers';

@Component({
  selector: 'app-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.scss']
})
export class PortfolioPageComponent {
  portfolioData: PortfolioData|null = null

  pillarsData: Array<PillarsData> = []
  ctx_companies_result_chart: any
  ctx_company_ratio_chart: any
  ctx_company_sector_chart: any
  chartjs_companies_result_chart: Chart|undefined
  chartjs_company_ratio_chart: Chart<"doughnut">|undefined
  chartjs_company_sector_chart: Chart<"doughnut">|undefined
  text_company_ratio: string = "No data, try to reload the page"
  data_are_loading: boolean = false
  isBrowser = false;
  isSmallScreen: boolean = false;
  average_vip_score: number = 0

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pillarsService: PillarsService,
    private title: Title,
    private meta: Meta,
    private navigation: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.isBrowser  = isPlatformBrowser(this.platformId);
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => {
          this.isSmallScreen = result.matches;
        });
    }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      const tempPortfolioId = param.get('portfolio_id')
      if(tempPortfolioId){
        this.portfolioData = getPortfolioData(tempPortfolioId)
        this.loadPortfolio()
      } else {
        this.router.navigate(['/'])
      }
    })

    const tempPortfolioId = this.activatedRoute.snapshot.paramMap.get('portfolio_id')
    if(tempPortfolioId){
      this.portfolioData = getPortfolioData(tempPortfolioId)
      this.loadPortfolio()
    } else {
      this.router.navigate(['/'])
    }

    Chart.register(...registerables)

    if(this.isBrowser){
      this.ctx_companies_result_chart = this.elementRef.nativeElement.querySelector('#companies_result_chart');
      this.ctx_company_ratio_chart = this.elementRef.nativeElement.querySelector('#company_ratio_chart')
      this.ctx_company_sector_chart = this.elementRef.nativeElement.querySelector('#company_sector_chart')

      this.chartjs_companies_result_chart = new Chart(this.ctx_companies_result_chart, {
        type: 'bar',
        data: {labels: [], datasets: []},
        options: {
          indexAxis: 'y',
          aspectRatio: 0.5,
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: `${this.portfolioData?.portfolio_name} Pillars Analysis`,
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  label += context.formattedValue
                  label += "\n(click to get more)"
                  // if (context.parsed.y !== null) {
                  //   // label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                    
                  // }
                  return label;
                }
              }
            }

          },
          onClick: (e: any, activeEls) => {
            if(activeEls && activeEls.length > 0){
              const datasetIdx = activeEls[0].datasetIndex
              const dataIdx = activeEls[0].index
              const selectedSymbol = this.pillarsData[dataIdx].symbol
              // const selectedSymbol = e.chart.data.labels[dataIdx]
              console.log("Selected symbol:", selectedSymbol)
              this.goToCompanyPage(selectedSymbol)
            }
          },  
        },
      })

      this.chartjs_company_sector_chart = new Chart(this.ctx_company_sector_chart, {
        type: 'doughnut',
        data: {labels: [], datasets: []},
        options: {
          indexAxis: 'x',
          aspectRatio: 0.9,
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: 'Sectors of company in the portfolio',
            },
          },
        },
      })
      
      this.chartjs_company_ratio_chart = new Chart(this.ctx_company_ratio_chart, {
        type: 'doughnut',
        data: {labels: [], datasets: []},
        options: {
          indexAxis: 'x',
          aspectRatio: 0.9,
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: 'Company below vs above average',
            },
          },
        },
      })
    }

    this.refreshPortfolio()
  }

  loadPortfolio(): void {
    console.log("Loading portfolio: ", this.portfolioData)
    if(this.portfolioData == null){
      this.router.navigate(['/'])
      return
    }
    this.title.setTitle(`NeoTradr - ${this.portfolioData.portfolio_name}`)
    this.meta.updateTag({property: 'og:title', content: `NeoTradr - ${this.portfolioData.portfolio_name}`})
    this.meta.updateTag({property: 'og:site_name', content: `NeoTradr - ${this.portfolioData.portfolio_name}`})
    this.meta.updateTag({property: 'og:description', content: `Portfolio analysis for ${this.portfolioData.portfolio_name}`})
    
    this.refreshPortfolio()
  }

  refreshPortfolio(): void {
    this.data_are_loading = true
    if(!this.portfolioData){
      console.log("Trying to refresh portfolio for null portfolio data, cancelling!")
      return
    }
    this.pillarsService.getPortfolioPillars(
      this.portfolioData.cache_name, 
      this.pillarsService._buildRestUrlForResource(this.portfolioData.rest_resource_name),
      this.pillarsService._buildStaticUrlForResource(this.portfolioData.static_resource_name))
      .subscribe(pillarsOfSymbols => {
        if(pillarsOfSymbols){
          this.pillarsData = pillarsOfSymbols.filter(x => x)
          this.pillarsData.sort((a, b) => a.score < b.score ? 1 : -1)
          this.average_vip_score = this.pillarsData.reduce((prev, cur) => prev + cur.score, 0)/this.pillarsData.length
          if(this.isBrowser){
            this.setChartData(this.pillarsData)
          }
        } else {
          console.log('You should reload the page, no data received from server')
        }
      this.data_are_loading = false
    })
  }

  setChartData(allPillarsData: Array<PillarsData>): void {
    if(this.chartjs_companies_result_chart){
      const labels = allPillarsData.map(pilData => pilData.company_name ? `${pilData.company_name} (${pilData.symbol})` : pilData.symbol)
      const values = allPillarsData.map(pilData => pilData.score)
      const scoreAvg = values.reduce((prev, cur) => prev + cur, 0)/values.length
      const nbBelowAvg = values.filter(val => val <= scoreAvg).length
      const nbAboveAvg = values.filter(val => val > scoreAvg).length
      const backgroundColors = allPillarsData.map(pilData => getColorFromScore(pilData.score))

      if(nbAboveAvg/(nbBelowAvg+nbAboveAvg) < 0.35){
        this.text_company_ratio = `${nbAboveAvg} companies seems to have good value`
      } else if(nbAboveAvg/(nbBelowAvg+nbAboveAvg) > 0.65) {
        this.text_company_ratio = "No company seem to have a better value compared to others"
      } else {
        this.text_company_ratio = "No company seem to have a better value compared to others"
      }

      this.chartjs_companies_result_chart.config.data.labels = labels
      const datasets: Array<ChartDataset> = [{
        label: 'Score avg',
        data: Array<number>(values.length).fill(scoreAvg),
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 2,
        type: 'line'
      }, {
        label: 'VIP Score',
        data: values,
        borderColor: backgroundColors,
        backgroundColor: backgroundColors,
        borderWidth: 2,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      }]
      this.chartjs_companies_result_chart.config.data.datasets = datasets
      this.chartjs_companies_result_chart.update()

      if(this.chartjs_company_sector_chart){
        const sector_count:Map<string, number> = new Map();
        for (const pilData of allPillarsData) {
          if(pilData.company_sector !== undefined){
            if(sector_count.has(pilData.company_sector)){
              const cur_count = sector_count.get(pilData.company_sector) ? sector_count.get(pilData.company_sector)! : 1
              sector_count.set(pilData.company_sector, cur_count+1)
            } else {
              sector_count.set(pilData.company_sector, 1);
            }
          }
        }
        console.log("Distinct sectors: ", [...sector_count.entries()])
        this.chartjs_company_sector_chart.config.data.labels = [...sector_count.keys()]
        this.chartjs_company_sector_chart.config.data.datasets = [{
          data: [...sector_count.values()],
          hoverOffset: 4
        }]
      
        this.chartjs_company_sector_chart.update()
      }


      if(this.chartjs_company_ratio_chart){
        this.chartjs_company_ratio_chart.config.data.labels = ['Company below average', 'Company above average']
        this.chartjs_company_ratio_chart.config.data.datasets = [{
          data: [nbBelowAvg, nbAboveAvg],
          backgroundColor: [
            'rgb(255, 100, 100)',
            'rgb(100, 255, 100)',
          ],
          hoverOffset: 4
        }]
      
        this.chartjs_company_ratio_chart.update()
      }
    } else {
      console.error("Chart is not defined")
    }
  }

  goToCompanyPage(symbol: string): void {
    this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
  }

  back(): void {
    this.navigation.back()
  }
}
