import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class FreeTrialService {
  private numberOfAnalyses: number;
  public updatedAnalysis: EventEmitter<number> = new EventEmitter()

  constructor() {
    const value = this.getNumberOfAnalysesFromCookie()
    if(value == null){
      this.numberOfAnalyses = 50;
    } else {
      this.numberOfAnalyses = value;
    }
  }

  getNumberOfAnalyses(): number {
    return this.numberOfAnalyses;
  }

  isFreeTrialAvailable(): boolean {
    return this.getNumberOfAnalyses() <= 0 ? false : true
  }

  decrementAnalysisCount(): void {
    if(this.numberOfAnalyses > 0){
      this.numberOfAnalyses--;
      this.updateCookie(); // Update the cookie whenever the counter changes
    }
    this.updatedAnalysis.emit(this.numberOfAnalyses)
  }
  
  private getNumberOfAnalysesFromCookie(): number | null {
    const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('analysisCounter='));
    
    if(cookieValue) {
      return parseInt(cookieValue.split('=')[1]);
    }
    return null;
  }

  private updateCookie(): void {
    document.cookie = `analysisCounter=${this.numberOfAnalyses}; max-age=31536000`; // Set cookie to expire in 1 year
  }
}
