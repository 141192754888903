import { Component, Input } from '@angular/core';
import { productDescription } from 'src/app/pages/subscription-page/subscription-page.component';

@Component({
  selector: 'app-subscription-paywall',
  templateUrl: './subscription-paywall.component.html',
  styleUrls: ['./subscription-paywall.component.scss']
})
export class SubscriptionPaywallComponent {
  @Input() isVisible: boolean = false;
  productDescription = productDescription
/*
 This component is taking all the screen space.
 It is displayed when the user is not subscribed to the service.
 It is a panel with the detail of the subscription plan advantages.
 A Close button as cross is available to close the panel.
 The design is responsive to adapt to the screen size.
*/

  ngOnInit(): void {
  }
  
  closePanel(): void {
    this.isVisible = false;
  }
}
