import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core';
import { NeoTradrUser, UserService } from '../../core/user.service';
import { LoginService } from '../../core/login.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SubscriptionButtonComponent } from '../../components/subscription-button/subscription-button.component';
import { isPlatformBrowser } from '@angular/common';
import { StripeService } from '../../core/stripe.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {
  ntUser: NeoTradrUser | null = null;
  imgUrl: string|undefined = undefined
  user_settings: {
    neotradr_receive_weekly_report: boolean,
    neotradr_receive_daily_report: boolean,
  } = {
    neotradr_receive_weekly_report: false,
    neotradr_receive_daily_report: false,
  }
  isBrowser = false
  subscribedProduct?: Array<any>
  hasValidSubscription: boolean|null = null
  neotradr_experimental = environment.neotradr_experimental

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    private stripeService: StripeService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.isBrowser  = isPlatformBrowser(this.platformId);
    }

  ngOnInit(): void {
    if(this.isBrowser){
      this.getUser()
      this.loginService.isLoggedIn.subscribe(isLoggedIn => {
        console.log('User isLoggedIn ?:', isLoggedIn)
        if(isLoggedIn){
          this.getUser(true)
        } else {
          console.log('Not logged in yet')
        }
      })
    }
  }

  getUser(forceRefresh:boolean = false): void {
    this.loginService.getIdToken().then(idToken => {
      console.log("idToken:", idToken)
      this.userService.getUser(idToken, forceRefresh).subscribe(user => {
        console.log("user: ", user)

        this.ntUser = user
        this.imgUrl = this.ntUser.picture
        if(user?.neotradr_receive_weekly_report){
          this.user_settings.neotradr_receive_weekly_report = !!user.neotradr_receive_weekly_report
          this.user_settings.neotradr_receive_daily_report = !!user.neotradr_receive_daily_report
        }
      })
      
      this.subscribedProduct = []
      this.stripeService.getSubscribedProduct(idToken).subscribe(subscribedProduct => {
        this.subscribedProduct = subscribedProduct
        console.log('Sub products: ', subscribedProduct)
      })

      this.stripeService.hasValidSubscription(idToken).subscribe(hasValid => {
        this.hasValidSubscription = hasValid
        console.log('HasValidSubscription:', hasValid)
      })
    }).catch(err => {
      console.log('User page: ', err)
    })
  }

  saveSettings(): void {
    console.log('Must save: ', this.user_settings)
    this.loginService.getIdToken().then(idToken => {
      this.userService.saveSettings(idToken, this.user_settings).subscribe(resp => {
        console.log('saveSettings reply: ', resp)
        this.getUser(true)
      })
    })
  }

  onLogout(): void {
    this.loginService.logout()
    this.router.navigate([''])
  }

  manageSubscription(): void {
    this.stripeService.manageSubscription()
  }

  refreshSubscription(): void {
    this.getUser()
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('User isLoggedIn ?:', isLoggedIn)
      if(isLoggedIn){
        this.getUser(true)
      } else {
        console.log('Not logged in yet')
      }
    })
  }
}
