import { Component, ElementRef, OnInit } from '@angular/core';
import { PillarData, PillarsService, AllSymbolData } from '../../core/pillars.service';
import { FinancialData } from '../../core/common_types';
import { getLocalStorageItem, setLocalStorageItem } from '../../core/local-storage-manager';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SymbolData } from '../../components/symbol-list/symbol-list.component';
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { convert_ext_to_int_symbol, getColorFromScore, getLatestFidFromAllData } from '../../core/helpers';
import { NavigationService } from '../../navigation.service';
import { formatCurrency, formatNumber, formatPercent } from '@angular/common';
import { LoginService } from '../../core/login.service';
import { computeAllTechnicalPillars, computeTechnicalData, extractTechnicalSentiment } from '../../components/technical-pillars-list/technical_pillars';
import { DetailedPillarCategories } from '../../components/radar-view/detailed-radar-view/detailed-radar-view.component';
import { environment } from 'src/environments/environment';
import { UserService } from '../../core/user.service';
import { StripeService } from '../../core/stripe.service';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { Observable, Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { FreeTrialService } from '../../core/free-trial.service';

interface MetricData {
  name: string,
  value: string,
  unit?: string
}

interface TimePlotData {
  labels: Array<any>,
  values: Array<number>
}


@Component({
  selector: 'app-comparison-page',
  templateUrl: './comparison-page.component.html',
  styleUrls: ['./comparison-page.component.scss']
})
export class ComparisonPageComponent {
  isLoading: boolean = false
  _isLoggedIn: boolean = false
  isSmallScreen: boolean = false
  isExtraSmallScreen: boolean = false
  shortHightlights: boolean = true

  favoriteList: Array<string> = []
  hasValidSubscription: boolean|null = null
  hasValidSubscriptionObservable: Subscription|undefined

  getColorFromScore = getColorFromScore

  pillarsData: [Array<PillarData>|null, Array<PillarData>|null] = [null, null]
  riskList: [Array<string|null>|null, Array<string|null>|null] = [null, null]
  metrics: [Array<MetricData>|null, Array<MetricData>|null] = [null, null]
  vipScore: [number|undefined, number|undefined] = [undefined, undefined]
  // companySymbol: [string|undefined, string|undefined] = ["AAPL", "MSFT"]
  companySymbol: [string, string] = ["", ""]
  companyName: [string|undefined, string|undefined] = [undefined, undefined]
  companyDescription: [string|undefined, string|undefined] = [undefined, undefined]
  companySector: [string | undefined, string|undefined] = [undefined, undefined]
  companyIndustry: [string|undefined, string|undefined] = [undefined, undefined]
  categoriesDetailed: [DetailedPillarCategories, DetailedPillarCategories] = [{
      valuation: [],
      profitability_growth: [],
      financial_health: [],
      liquidity_debt: [],
      return_on_investment: []},
      {valuation: [], profitability_growth: [], financial_health: [], liquidity_debt: [], return_on_investment: []}
    ]
  tapScoreState: 'buy'|'no_buy'|'no_decision' = 'no_decision'
  
  priceData: [{labels: Array<any>, values: Array<number>}, {labels: Array<any>, values: Array<number>}] = [{labels: [], values: []}, {labels: [], values: []}]
  // perData: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  roicData: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  revenueData: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  netIncomeData: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  freeCashflow: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  shareOutstanding: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  longTermLiabilities: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  dividends: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]
  // marketCap: [TimePlotData, TimePlotData] = [{labels: [], values: []}, {labels: [], values: []}]

  freeTrial: boolean

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pillarsService: PillarsService,
    private title: Title,
    private meta: Meta,
    private navigation: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private loginService: LoginService,
    private userService: UserService,
    private el: ElementRef,
    private stripeService: StripeService,
    private freeTrialService: FreeTrialService) {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });
      this.breakpointObserver.observe([Breakpoints.XSmall])
      .subscribe(result => {
        this.isExtraSmallScreen = result.matches;
      });

      this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
      this.title.setTitle("NeoTradr - Company Comparison")
    }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('queryParams: ', params)
      let company1 = params['company1']
      if(company1){
        this.companySymbol[0] = company1
      }
      let company2 = params['company2']
      if(company2){
        this.companySymbol[1] = company2
      }
      console.log('Comparing:', company1, company2)
      
      if(this.companySymbol[0]){
        this.loadSymbol(this.companySymbol[0], 0)
      }
      if(this.companySymbol[1]){
        this.loadSymbol(this.companySymbol[1], 1)
      }
    })

    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('User isLoggedIn ?:', isLoggedIn)
      this._isLoggedIn = isLoggedIn
    })

    this.refreshActiveSubscription()
    this.refreshFavoriteList()
  }

  loadFirstSymbol(symbol: string) {
    this.loadSymbol(symbol, 0)
  }

  loadSecondSymbol(symbol: string) {
    this.loadSymbol(symbol, 1)
  }

  loadSymbol(symbol: string, index: number): void {
    console.log('Loading: ', symbol,' as company ', index)
    this.isLoading = true
    this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
    if(index === 0){
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {company1: symbol},
        queryParamsHandling: 'merge',
        skipLocationChange: false});
  
    } else if(index === 1){
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {company2: symbol},
        queryParamsHandling: 'merge',
        skipLocationChange: false});
    }
    this.pillarsService.getAllSymbolData(symbol).subscribe(allSymbolData => {
      if(allSymbolData){
        this.companySymbol[index] = allSymbolData.symbol
        this.companyName[index] = this.pillarsService.getCompanyName(allSymbolData)
        this.companyDescription[index] = this.pillarsService.getCompanyDescription(allSymbolData)
        this.companySector[index] = this.pillarsService.getCompanySector(allSymbolData)
        this.companyIndustry[index] = this.pillarsService.getCompanyIndustry(allSymbolData)
        console.log(`Loading data for ${this.companySymbol[index]} (${this.companyName[index]})`)  

        const resP1 = this.pillarsService.computePillar1(allSymbolData)
        const resP2 = this.pillarsService.computePillar2(allSymbolData)
        const resP3 = this.pillarsService.computePillar3(allSymbolData)
        const resP4 = this.pillarsService.computePillar4(allSymbolData)
        const resP5 = this.pillarsService.computePillar5(allSymbolData)
        const resP6 = this.pillarsService.computePillar6(allSymbolData)
        const resP7 = this.pillarsService.computePillar7(allSymbolData)
        const resP8 = this.pillarsService.computePillar8(allSymbolData)
        const resP9 = this.pillarsService.computePillar9(allSymbolData)
        const resP10 = this.pillarsService.computePillar10(allSymbolData)
        const resP11 = this.pillarsService.computePillar11(allSymbolData)
        const resP12 = this.pillarsService.computePillar12(allSymbolData)
        const resP13 = this.pillarsService.computePillar13(allSymbolData)
        const resP14 = this.pillarsService.computePillar14(allSymbolData)
        const resP15 = this.pillarsService.computePillar15(allSymbolData)
        const respP16 = this.pillarsService.computePillar16(allSymbolData)
        const respP17 = this.pillarsService.computePillar17(allSymbolData)
        const respP18 = this.pillarsService.computePillar18(allSymbolData)
        
        this.pillarsData[index] = [
          resP1, resP2, resP3, resP4, resP5, resP6, resP7, resP8,
          resP9, resP10, resP11, resP12, resP13, resP14, resP15,
          respP16, respP17, respP18]
        this.vipScore[index] = this.pillarsService.computeVipScore(this.pillarsData[index]!)

        const technical_data_for_pillars = computeTechnicalData(this.priceData[index].values)
        const technical_pillars_results = computeAllTechnicalPillars(technical_data_for_pillars!)
        this.tapScoreState = extractTechnicalSentiment(technical_pillars_results).sentiment

        // This is done this way to trigger Angular change detection
        this.categoriesDetailed[index] = {
          valuation: [resP1, resP12, resP14, respP16, resP8],
          profitability_growth: [resP2, resP3, resP4, resP7, resP9, resP11],
          financial_health: [resP6, resP10, resP15],
          liquidity_debt: [resP15, respP18, resP10, resP6],
          return_on_investment: [respP17, resP13, resP5]
        }
        // Extraction of displayed data
        this.priceData[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.STOCK_PRICE)
        // this.perData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.PER)
        this.roicData[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.ROIC)
        this.revenueData[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.REVENUE)
        this.netIncomeData[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.NET_INCOME)
        this.shareOutstanding[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.NUMBER_SHARES)
        this.longTermLiabilities[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.TOTAL_NON_CURRENT_LIABILITIES)
        this.freeCashflow[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.FREE_CASHFLOW)
        this.dividends[index] = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.DIVIDEND)
        // this.marketCap = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.MARKET_CAPITALIZATION)

        // Computing metrics 
        this.metrics[index] = []
        const addMetricIfAvailable = (m_name: string, unit: 'percent'|'currency'|'number', fid: string, valueTransformationClbk?: ((val: number)=>number)) => {
          const latestData = getLatestFidFromAllData(allSymbolData, fid)
          if(latestData){
            let val = Math.round(latestData.value*100)/100
            if(valueTransformationClbk){
              val = valueTransformationClbk(val)
            }
            if(unit == 'percent'){
              this.metrics[index]!.push({name: m_name, value: `${formatPercent(val, this.pillarsService.locale)}`, unit: unit})
            } else if(unit == 'currency'){
              this.metrics[index]!.push({name: m_name, value: `${formatCurrency(val, this.pillarsService.locale, '$')}`, unit: unit})
            } else {
              this.metrics[index]!.push({name: m_name, value: `${formatNumber(val, this.pillarsService.locale)}`, unit: unit})
            }
          } else {
            console.log(`Error could not find latest data for: ${fid}`)
          }
        }
        addMetricIfAvailable('Stock price', 'currency', FinancialData.ID.STOCK_PRICE)
        addMetricIfAvailable('Price/Earnings (PER)', 'number', FinancialData.ID.PER)
        addMetricIfAvailable('Price/Book (P/B)', 'number', FinancialData.ID.PRICE_TO_BOOK_RATIO)
        addMetricIfAvailable('ROIC', 'percent', FinancialData.ID.ROIC)
        addMetricIfAvailable('ROE', 'percent', FinancialData.ID.RETURN_ON_EQUITY)
        addMetricIfAvailable('Market Cap', 'currency', FinancialData.ID.MARKET_CAPITALIZATION)
        // addMetricIfAvailable('DCF Fair price', 'currency', FinancialData.ID.DCF_VALUATION)

      } else {
        this.companyName[index] = "Unknown company"
        this.companySymbol[index] = "No Symbol"
        this.vipScore[index] = 0
        this.companyDescription[index] = "-"
        this.companySector[index] = "-"
        this.companyIndustry[index] = "-"
        this.pillarsData[index] = []
      }
      
      this.isLoading = false
    })
  }

  // onSymbolInput(symbol: string): void {
  //   this.goToCompanyPage(symbol)
  // }

  // goToCompanyPage(symbol: string): void {
  //   this.router.navigate(['company', symbol])
  // }

  setSymbolToHistory(symbol: string, score: number, pillars_data: Array<PillarData>): void {
    let savedHistory:({[key: string]: SymbolData})|null = getLocalStorageItem('symbolhistory')
    if(!savedHistory){
      savedHistory = {}
    }
    savedHistory[symbol] = {
      symbol: symbol,
      timestamp: new Date(),
      score: score,
      pillars_data: pillars_data
    }
    setLocalStorageItem('symbolhistory', savedHistory, 60*60*24*365*15)
  }

  back(): void {
    this.navigation.back()
  }

  scrollToSection(name: string) {
    const element = this.el.nativeElement.querySelector('#'+name);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  isFavorite(index: number): boolean {
    if(!this.favoriteList){
      return false
    }
    return this.companySymbol[index] ? this.favoriteList.includes(this.companySymbol[index]!) : false
  }

  async swapFavorite(index: number) {
    if(!this.companySymbol){
      return 
    }
    const idToken = await this.loginService.getIdToken()
    this.userService.setFavoriteStatus(idToken, this.companySymbol[index]!, this.isFavorite(index)).subscribe(result => {
      console.log("Favorite update result: ", result)
      //Check error here and display message if error 
      if(result){
        this.refreshFavoriteList()
      }
    })
  }

  async refreshFavoriteList() {
    const idToken = await this.loginService.getIdToken()
    this.userService.getFavoriteList(idToken).subscribe(favoriteList => {
      console.log('Favorite list: ', favoriteList)
      if(favoriteList != null){
        this.favoriteList = favoriteList
      }
    })
  }

  async refreshActiveSubscription() {
    const idToken = await this.loginService.getIdToken()
    this.hasValidSubscriptionObservable = this.stripeService.hasValidSubscription(idToken).subscribe(hasValid => {
      this.hasValidSubscription = hasValid
      console.log('HasValidSubscription:', hasValid)
    })
  }

  userIsLogged(): boolean {
    /**
     * Return true is user is logged in or Free trial otherwise false
     */
    if(this._isLoggedIn){
      return true
    }
    if(this.freeTrial){
      return true
    }
    return false
  }
  userHasPremium(): boolean {
    /**
     * Return true is user has access through Premium or Free trial otherwise false
     */
    if(this._isLoggedIn && this.hasValidSubscription){
      return true
    }
    if(this.freeTrial){
      return true
    }
    return false
  }

  ngOnDestroy(): void {
    console.log('Destroying ComparisonPage')
  }

}
